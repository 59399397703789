import { Controller } from '@hotwired/stimulus'
import Channel from '../channels/channel'
import ForceGraph from '../libs/forcegraph'

export default class extends Controller {
  static values = { keyword: Array, meetingId: Number, networkId: Number };
  static targets = ["targetList"];
  connect() {
    console.log('connecting to network controller');
    this.networkDashboardChannel = new Channel('NetworkDashboardChannel', this, {
      meeting_id: this.meetingIdValue,
      network_id: this.networkIdValue
    });
    // console.log(this);
    this.fetch_answers();
    // console.log(this);
  }
  static json = {}
  static selectedId

  fetch_answers() {
    fetch(`/networks/${this.networkIdValue}/result`)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        // console.log(json);
        this.json = json
        this.drawGraph(json)
        this.keywordList(json.nodes)
      })
  }
  drawGraph(json) {
    document.getElementById('graph').innerHTML = "";
    this.chart = new ForceGraph(json, {
      nodeId: d => d.id,
      nodeGroup: d => d.group,
      nodeTitle: d => d.title,
      nodeRank: d => d.rank,
      nodeImage: d => d.img,
      nodeSize: d => Math.max(20, d.rank),
    })
    document.getElementById('graph').append(this.chart.graph())
  }

  keywordList(nodes) {
    var keyword = nodes.filter(node => node.group == 'keyword')
      .sort((a, b) => {
        return b.count - a.count;
      })

    console.log(keyword);
    document.getElementById('keyword-list').innerHTML = "";
    var keywordList = d3
      .select('#keyword-list')
      .selectAll('div')
      .data(keyword)
      .join('div')
      .attr('class', 'tw-border-b tw-border-gray-600')
      .attr('data-action', d => 'mouseenter->network-dashboard#mouseEnter mouseleave->network-dashboard#mouseLeave')
      .append('div')
      .attr('class', d => {
        var cls = 'tw-w-full tw-pl-4 tw-pr-6 tw-py-2 tw-rounded-l-full tw-break-all tw-flex tw-flex-nowrap tw-justify-between';
        if (d.count == keyword[0].count) { // max user
          cls += ' hover:tw-bg-mainPurple'
        } else if (d.count < keyword[0].count) { // 2nd and 3rd rank
          cls += ' hover:tw-bg-mainOrange'
        }
        return cls
      })

    keywordList
      .append('span')
      .text(d => {
        return d.title })

    keywordList
      .append('span')
      .attr('class', 'tw-ml-auto tw-break-normal tw-whitespace-nowrap')
      .text(d => {
        return `${d.count}명` })
  }
  mouseEnter(event) {
    if (this.selectedId != event.target.__data__.id) {

      this.selectedId = event.target.__data__.id
      let userArray = this.chart.select(this.selectedId);
      this.targetListTarget.querySelector('#target-list').textContent = userArray.join(', ');
    }
  }
  mouseLeave(event) {
    this.targetListTarget.querySelector('#target-list').textContent = '';
    this.chart.select()
  }
  fatal(payload) {
    location.href = "/";
  }
  reload(payload) {
    window.location.reload();
  }

  select(payload) {
    this.fetch_answers();
  }
}
